import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget";
import CartStatusBar from "../../Components/CartStatusBar";
import CheckoutWrapper from "../../Components/CheckoutWrapper";
import CartSection from "../../Components/CartSection";
import PaymentSection from "../../Components/PaymentSection";
import CartFooterSection from "../../Components/CartFooterSection";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
`;

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > .hint-wrapper {
    margin-top: 30px;
    margin-bottom: 46px;
    padding: 30px;
    border: 1px solid #dadada;
    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    & > p {
      font-size: 14px;
      color: #0075c1;
    }
  }
`;

const Footer = styled.div`
  height: 120px;
  background-color: #0075c1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 160px;

  & > button {
    width: 160px;
    height: 40px;
    border: 0;
    font-size: 14px;
    color: #0075c1;
    background-color: #fff;
    cursor: pointer;
  }
`;

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <PageWrapper>
        <CheckoutWrapper
          infoSection={this._renderInfoSection()}
          cartSection={<CartSection orderId={1} />}
        />
        <CartFooterSection text="前往結帳" onClick={this._submit} />
      </PageWrapper>
    );
  }

  _submit = () => {
    const { navActions } = this.props;

    navActions.push("/order?id=1");
  };

  _renderInfoSection = () => {
    return (
      <InfoWrapper>
        <CartStatusBar step={2} />
        <div className="hint-wrapper">
          <p>親愛的顧客您好</p>
          <div>&nbsp;</div>
          <p>
            我們已經收到您的訂單與付款資訊，請您於出國當天，到您選擇的取件的機場櫃台，出示此畫面，完成取件。
          </p>
          <p>
            提醒您，卡片插入手機後即開通，請您務必開啟「行動數據」與「數據漫遊」，即可使用網路。
          </p>
          <p>
            系統會於取貨後10個工作天內寄送電子發票至您填寫的E-Mail信箱。(如未如期收到，再請您與我們聯繫。）
          </p>
          <div>&nbsp;</div>
          <p>TEL25 感謝您的訂購</p>
        </div>
        <PaymentSection orderId={1} />
      </InfoWrapper>
    );
  };
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(CheckoutPage)
);
